import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'Ecliptic-Solutions', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Welcome to ',
  name: 'Ecliptic Solutions',
  subtitle: '',
  cta: ''
};

// ABOUT DATA
export const aboutData = {
  img: 'logo.png',
  paragraphOne: 'We develop and create high-quality products and services, that provide the best experience in the Information technology area. Working with fresh new high-end technologies to deliver a Full-Stack development service that truly makes the difference, we are based in Costa Rica and we focus on integrating top-quality talent into our teams.',
  paragraphTwo: 'Our main goal is to fulfill the need for sustainable and scalable software in the market. With our drive and passion, we are up to the challenge to materialize your business objectives and ideas in a disruptive fashion that stands out. We believe in innovation and in the value that our services will be able to provide to your projects.',
  paragraphThree: 'We are looking forward to working together with you.',
  resume: '' // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'frontend1.png',
    title: 'Front-End',
    info: 'The tech market is growing exponentially, that is why here at Ecliptic Solutions we are dedicated to providing you with the best options when it comes to new and upcoming  technologies. We have experience in many languages, libraries and frameworks such as Javascript, Angular, React, React Native, and more. All to provide you with a satisfying custom software experience to remember.',
    info2: '',
    //url: '#about',
    repo: 'https://eclipticsolutions.com/', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'backend1.png',
    title: 'Back-End',
    info: `A well-made and higly optimized Back-End is the key for any successful project, that's why we take things seriously when it comes to this area. We specialize in a wide array of new technologies like NodeJs, Deno, PHP and Ruby among others. Our specializations allow us a high-degree of adaptability to the needs of our clients and the market.`,
    info2: '',
    //url: '#about',
    repo: 'https://eclipticsolutions.com/', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'staff.png',
    title: 'Staff Augmentation',
    info: `We offer top-quality talent in staff augmentation. Our professionals are more than qualified to perform any given task, ensuring that we will accomplish your business objectives in a prompt and professional manner. Time is the most valuable asset in this industry and we push hard to meet your expectations. `,
    info2: '',
    //url: '#about',
    repo: 'https://eclipticsolutions.com/', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'twitter',
      url: 'https://twitter.com/eclipticsolut',
    },
    {
      id: uuidv1(),
      name: 'instagram',
      url: 'https://www.instagram.com/eclipticsolutions/',
    },
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/company/ecliptic-solutions',
    },
    {
      id: uuidv1(),
      name: 'facebook',
      url: 'https://www.facebook.com/eclipticsolutions/',
    },
  ],
};

// Github start/fork buttons
//export const githubButtons = {
  //isEnabled: true, // set to false to disable the GitHub stars/fork buttons
//};
