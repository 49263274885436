import React from 'react';
import Title from '../Title/Title';
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';
import { serviverId420, template420, userId420 } from '../../config/emailConfig';
import { errorHandler } from '../../errorHandler/errorHandler';

export const Form = ({ closeModal }) => {
  function closeForm() {
    closeModal();
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  //console.log(errors)
  const onSubmit = (data, e) => {
    // Send Email Function
    e.preventDefault();
    emailjs.sendForm(serviverId420, template420, e.target, userId420).then(result => {
      let res = errorHandler(result);
      console.log(res);
      if (res.status) {
        closeForm();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title title="Let's Talk" />
      <section id="form-style">
        <div className="form-group">
          <label className="form-label">Name*</label>
          <input
            {...register('name', {
              required: true,
            })}
            name="name"
            className="form-control"
          />
          {errors?.name?.type === 'required' && (
            <p className="formAlert">This field is required ⚠</p>
          )}
        </div>

        <div className="form-group">
          <label className="form-label">Email*</label>
          <input
            {...register('email', {
              required: true,
            })}
            type="text"
            className="form-control"
            id="email"
            name="email"
          />
          {errors?.email?.type === 'required' && (
            <p className="formAlert">This field is required ⚠</p>
          )}
        </div>

        <div className="form-group">
          <label className="form-label">Phone</label>
          <input className="form-control" id="name" name="phone" />
        </div>

        <div className="form-group">
          <label className="form-label">Company</label>
          <input className="form-control" id="name" name="company" />
        </div>

        <div className="form-group">
          <label className="form-label">Description*</label>
          <textarea
            {...register('description', {
              required: true,
            })}
            type="text"
            className="form-control"
            rows="10"
            name="description"
          />
          {errors?.description?.type === 'required' && (
            <p className="formAlert">This field is required ⚠</p>
          )}
        </div>

        <div className="form-group">
          <input className="form-control cta-btn cta-btn--submit" type="submit" value="Submit" />
        </div>
      </section>
    </form>
  );
};
export default Form;
